import React from 'react';
import {
    Text,
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    Image,
    FlatList
} from 'react-native';
import px from '../utils/px.js';
import API, { axiosAPI } from './API.js';
import { BaseStyles } from './CommonStyle.js';
import ListFooter from './ListFooter.js';
import { windowWidth } from './Platform.js';
import VideoItem from './VideoItem.js';

export default class SearchContent extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            tagData: [],
            videoData: [],
            keywords: '',
            showFoot: 0,
            selectedIdx: 0,
            showRightContant: [],
            clickItems: []
        };
        this.currPage = 0;
    }

    componentDidMount = async () => {
        const tagData = await API.loadVideoSearch(this.props.type);
        this.setState({
            tagData,
            showRightContant: tagData[0]
        })
    }

    _onClickItem = (idx, item) => {
        this.setState({
            selectedIdx: idx,
            showRightContant: item
        });
    }

    renderTagItem = ({ item, index }) => {
        return (
            <TouchableOpacity
                key={index}
                activeOpacity={1}
                onPress={() => this.changeTag(item)}
            >

                <View style={{ width: px(150), borderRadius: 4, backgroundColor: BaseStyles.tagItem_bg_color, alignItems: 'center', margin: px(10) }}>
                    <Text numberOfLines={1} style={{ padding: px(8), fontSize: px(28), color: this.state.clickItems.includes(item.title) ? "#3274fc" : "#999999", fontWeight: '300', }}>{item.title}</Text>
                </View>

            </TouchableOpacity>
        )
    }

    renderVideoItem = ({ item, index }) => {
        return (
            <VideoItem item={item} width={px(352)} />
        )
    }

    _onEndReached = () => {
        if (this.state.showFoot != 0) {
            return;
        }
        this.currPage++;
        this.setState({ showFoot: 2 }, () => {
            this.loadVideoData(true);
        });
    };

    loadVideoByText = async (text) => {
        const { data: { data, count, page, pageSize } } = await axiosAPI.post('/search/video', { keywords: text, tags: [], page: this.currPage, pageSize: 10 });
        this.currPage = page;
        this.setState({
            keywords: text,
            videoData: data,
            showFoot: Math.ceil(count / pageSize) > this.currPage ? 0 : 1,
        })
    }

    loadVideoData = async (isConcat = false) => {
        if (this.state.clickItems.length === 0 && this.state.keywords == '')
            return;
        const { clickItems: tags, keywords, videoData } = this.state;
        const { data: { data, count, page, pageSize } } = await axiosAPI.post('/search/video', { keywords, tags, page: this.currPage, pageSize: 10 });
        this.currPage = page;
        this.setState({
            videoData: isConcat ? videoData.concat(data) : data,
            showFoot: Math.ceil(count / pageSize) > this.currPage ? 0 : 1,
        })
    }

    render() {
        const { showRightContant, tagData, videoData, clickItems } = this.state
        return (
            <>
                <View style={styles.container}>
                    <View style={{ marginTop: px(15) }}>
                        <ScrollView
                            showsVerticalScrollIndicator={false}>
                            {
                                tagData.map((item, index) => {
                                    return (
                                        <TouchableOpacity key={item.key} style={[styles.itemTextView, this.state.selectedIdx === index && styles.itemStyle
                                        ]}
                                            onPress={() => {
                                                this._onClickItem(index, item)
                                            }}>

                                            <Text style={styles.itemText}>
                                                {item.title}
                                            </Text>

                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </ScrollView>
                    </View>
                    <FlatList
                        data={showRightContant.data}
                        keyExtractor={(_, index) => index}
                        ListHeaderComponent={<Text style={{ marginTop: px(15), marginLeft: px(20), color: '#6e76aa' }}>{showRightContant.title}</Text>}
                        showsVerticalScrollIndicator={false}
                        renderItem={this.renderTagItem}
                        numColumns={3}
                    />

                </View>

                <View style={{
                    flexDirection: 'row',
                    width: windowWidth,
                    height: px(80),
                    alignItems: 'center'
                }}>
                    <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                    >
                        {
                            clickItems.map((item, index) => {
                                return (
                                    <TouchableOpacity key={index} style={styles.selectedTextView}
                                        onPress={() => {
                                            this.changeTag({ title: item })
                                        }}>

                                        <Text style={[styles.itemText, { color: '#999999' }]}>
                                            {item}
                                        </Text>
                                        <Image style={{ width: px(40), height: px(40), position: 'absolute', bottom: 0, right: 0 }} source={require("../../assets/src_image_icon_labelfilter_close.png")} />


                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ScrollView>
                </View>

                <FlatList
                    data={videoData}
                    style={{ flex: 1 }}
                    contentContainerStyle={{ alignItems: 'center' }}
                    keyExtractor={item => item.id}
                    showsVerticalScrollIndicator={false}
                    renderItem={this.renderVideoItem}
                    numColumns={2}
                    onEndReached={this._onEndReached}
                    onEndReachedThreshold={0.3}
                    ListFooterComponent={() => <ListFooter showFoot={this.state.showFoot} page={this.currPage} />}
                />

            </>


        )
    }

    changeKeyWords = (keywords) => {
        this.setState({
            keywords
        })
    }

    changeTag(item) {
        let empItems = this.state.clickItems.slice();
        if (empItems.includes(item.title)) {
            empItems.splice(empItems.indexOf(item.title), 1)
        } else {
            empItems.push(item.title);
        }

        this.setState({
            clickItems: empItems
        }, () => {
            this.goSearch(empItems)

        })
    }

    goSearch = () => {

        this.currPage = 0;
        this.loadVideoData()
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: BaseStyles.body_color,
        flexDirection: 'row',
        height: px(400)
    },

    itemTextView: {
        paddingVertical: px(15),
        width: px(200),
        backgroundColor: "#2f3350",
        justifyContent: 'center',
        alignItems: "center",
        fontSize: 19,
        borderBottomWidth: 1,
        borderColor: '#393d60',
    },

    selectedTextView: {
        paddingVertical: px(10),
        paddingHorizontal: px(30),
        marginHorizontal: px(8),
        backgroundColor: BaseStyles.tagItem_bg_color,
        justifyContent: 'center',
        alignItems: "center",
        fontSize: px(18),
        borderRadius: 4
    },

    itemText: {
        fontFamily: '',
        color: '#fff',
        fontSize: 16
    },

    itemStyle: {
        backgroundColor: "#3375fd",
    }
});