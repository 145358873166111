import React from 'react'
import { Text, View, StyleSheet, Pressable, TextInput ,Platform} from 'react-native';
import px from "../utils/px"
import API, { axiosAPI } from '../common/API';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import { getItem } from '../utils/Storage';
import StringUtils from '../utils/StringUtils';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pwd_phone:'',
            password:''
        }
    }

    onChangePwdPhone = (pwd_phone) => {
        this.setState({ pwd_phone });
    }

    onChangePwd = (password) => {
        this.setState({ password });
    }

    onSubmit2 = async (username, password) => {
        if (username == '') {
            $toast('请输入密码手机号或邮件')
            return;
        }
        if (password == '') {
            $toast('请输入密码')
            return;
        }

        const { data, error } = await axiosAPI.post('login', { username, password})
        if (error) {
            $toast(error.message)
            return;
        }

        await API.Info(data);

        this.props.navigation.navigate('Mine');
    }

    freeLogin = async () => {
        let mobile = await getItem('MOBILE');
        let proxy = await getItem('PROXY');
        let device = await getItem('DEVICE');
        let system = 'native';
        if (Platform.OS == 'web') {
          let u = navigator.userAgent;
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          system = isiOS ? 'ios' : "android";
        }

        if (StringUtils.isEmpty(device)) {
            device = StringUtils.randomString(40);
        }
        
        const { data, error } = await axiosAPI.post('register', { username : device, proxy , mobile ,system})

        if (error) {
            $toast(error.message)
            return;
        }

        $toast('游客登录成功');
        await API.Info(data);

        this.props.navigation.navigate('Mine');
    }

    render() {
        return (
            <View style={CommonStyles.body}>
                <View style={{
                    margin: px(30),
                }}>
                    <TextInput style={styles.inputTxt}
                        placeholder={'请输入您的手机号或邮箱'}
                        value={this.state.pwd_phone}
                        placeholderTextColor={BaseStyles.input_text_color}
                        onChangeText={(v) => this.onChangePwdPhone(v)}
                        underlineColorAndroid="transparent"
                        selectTextOnFocus={false}
                        keyboardType={'numeric'}
                    />
                    
                    <View style={{
                        marginVertical: px(30),
                    }}>
                    <TextInput style={styles.inputTxt}
                            placeholder={'请输入您的密码'}
                            value={this.state.password}
                            placeholderTextColor={BaseStyles.input_text_color}
                            onChangeText={(v) => this.onChangePwd(v)}
                            underlineColorAndroid="transparent"
                            secureTextEntry={true}
                        />
                    </View>
                    <Pressable onPress={() => this.onSubmit2(this.state.pwd_phone, this.state.password)} style={{
                        backgroundColor: '#FF9900',
                        padding: px(30),
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4
                    }}>
                        <Text style={{
                            color: '#fff'
                        }}>登录</Text>

                    </Pressable>
                    <View style={{
                        flexDirection:'row' , justifyContent:'space-between'
                    }}> 
                        <Text onPress={() => {
                            this.props.navigation.navigate('Register');
                        }} style={{
                            fontFamily:'',
                            padding: px(40),
                            color: '#fff',
                            fontSize: px(28)
                        }}>尚未注册?点击注册</Text>
                        <Text onPress={() => {
                            this.props.navigation.navigate('ResetPwd');
                        }} style={{
                            fontFamily:'',
                            padding: px(40),
                            color: '#fff',
                            fontSize: px(28)
                        }}>忘记密码</Text>
                    </View>
                    <View style={{
                        flexDirection:'row' , justifyContent:'space-between'
                    }}> 
                        <Text onPress={() => {
                            this.freeLogin()
                        }} style={{
                            fontFamily:'',
                            padding: px(40),
                            color: '#fff',
                            fontSize: px(28)
                        }}>游客模式登录</Text>
                    </View>
                </View>
            </View>
        )
    }

}

const styles = StyleSheet.create({
    inputTxt: {
        fontSize: px(28),
        color: '#fff',
        backgroundColor: BaseStyles.input_color,
        borderRadius: 4,
        padding: px(20),
        // borderWidth:1,
        // borderColor:'#fff'
    },

    loginBtn: {
        backgroundColor: '#ff4d4d',
        paddingVertical: px(30),
        width: px(200),
        marginLeft: px(30),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4
    }
});