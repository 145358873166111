import React from 'react';
import { StyleSheet, Image ,Text} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { navigationRef } from './NavigatorUtil';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import HomeScreen from '../page/Home'
import Hot from '../page/Hot'
import Mine from '../page/Mine'
import VideoDetail from '../page/VideoDetail'
import Splash from '../page/Splash'
import History from '../page/History';
import Favorite from '../page/Favorite';
import Charge from '../page/Charge';
import Share from '../page/Share';
import Comic from '../page/Comic';
import ComicDetail from '../page/ComicDetail';
import ComicPlay from '../page/ComicPlay';
import Search from '../page/Search';
import { BaseStyles } from './CommonStyle';
import Fuli from '../page/Fuli';
import PayVideo from '../page/PayVideo';
import Bind from '../page/Bind';
import Register from '../page/Register';
import px from '../utils/px';
import Result from '../page/Result';
import Actor from '../page/Actor';
import ActorDetail from '../page/ActorDetail';
import Login from '../page/Login';
import ResetPwd from '../page/ResetPwd';
import ViewUtils from '../utils/ViewUtils';

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

function HomeTabs() {
  return (
    <Tab.Navigator
      screenOptions={{
        lazy : true,
        headerShown: false,
        tabBarStyle: {
          backgroundColor: BaseStyles.tab_bottom_color,
          height:px(100),
          borderTopWidth: 0,
          paddingBottom:0
        },
        tabBarLabelStyle: {
          // margin: 0
        },
        // tabBarActiveTintColor: '#EE0D24',
        // tabBarInactiveTintColor: '#cccccc'
      }}
    >
      <Tab.Screen key={'Home'} name="Home" component={HomeScreen}
        options={{
          tabBarLabel: ({ focused, color, size }) => {
            return <Text style={focused ? styles.tabTextFocus : styles.tabText}>推荐</Text>;
          },
          tabBarIcon: ({ focused, color, size }) => {
            return <Image source={require('../../assets/v3/btnTj.png')} style={styles.tabIcon} />
          },
        }}
      />
      <Tab.Screen key={'Hot'} name="Hot" component={Hot}
        options={{
          tabBarLabel: ({ focused, color, size }) => {
            return <Text style={focused ? styles.tabTextFocus : styles.tabText}>热点</Text>;
          },
          tabBarIcon: ({ focused, color, size }) => {
            return <Image source={require('../../assets/v3/btnHot.png')} style={styles.tabIcon} />
          },
        }}
      />
      {/* <Tab.Screen key={'Fuli'} name="Fuli" component={Fuli}
        options={{
          tabBarLabel: ({ focused, color, size }) => {
            return <Text style={focused ? styles.tabTextFocus : styles.tabText}>福利</Text>;
          },
          tabBarIcon: ({ focused, color, size }) => {
            return <Image source={require('../../assets/v3/btnFl.png')} style={styles.tabIcon} />;
          },
        }}
      /> */}
      <Tab.Screen key={'Comic'} name="Comic" component={Comic}
        options={{
          tabBarLabel: ({ focused, color, size }) => {
            return <Text style={focused ? styles.tabTextFocus : styles.tabText}>漫画</Text>;
          },
          tabBarIcon: ({ focused, color, size }) => {
            return <Image source={require('../../assets/v3/btnComic.png')} style={styles.tabIcon} />;
          },
        }}
      />
      <Tab.Screen key={'Charge'} name="Charge" component={Charge}
        options={{
          tabBarLabel: ({ focused, color, size }) => {
            return <Text style={focused ? styles.tabTextFocus : styles.tabText}>会员</Text>;
          },
          tabBarIcon: ({ focused, color, size }) => {
            return <Image source={require('../../assets/v3/btnFl.png')} style={styles.tabIcon} />;
          },
        }}
      />
      <Tab.Screen key={'Mine'} name="Mine" component={Mine}
        options={{
          tabBarLabel: ({ focused, color, size }) => {
            return <Text style={focused ? styles.tabTextFocus : styles.tabText}>我的</Text>;
          },
          tabBarIcon: ({ focused, color, size }) => {
            return <Image source={require('../../assets/v3/btnHome.png')} style={styles.tabIcon} />;
          },
        }}
      />
    </Tab.Navigator>
  );
}

const AppContainer = () => {
  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator initialRouteName="Splash"
        screenOptions={{
          animationEnabled:true,
          headerMode: 'screen',
          headerTintColor: 'white',
          headerShadowVisible:false,
          headerLeft: () => (
            ViewUtils.getLeftButton()
          ),
          // headerTransparent:true,
          headerStyle: { backgroundColor: BaseStyles.tab_background_color  },
        }}>
        <Stack.Screen name="HomeTabs" component={HomeTabs} options={{ headerShown: false }} />
        <Stack.Screen key={'VideoDetail'} name="VideoDetail" component={VideoDetail} options={{ headerShown: false }} />
        <Stack.Screen key={'ComicDetail'} name="ComicDetail" component={ComicDetail} options={{ headerShown: false  }} />
        <Stack.Screen key={'ActorDetail'} name="ActorDetail" component={ActorDetail} options={{ headerShown: false  }} />

        <Stack.Screen key={'ComicPlay'} name="ComicPlay" component={ComicPlay} options={{ headerTitle: true }} />
        <Stack.Screen key={'Splash'} name="Splash" component={Splash} options={{ headerShown: false }} />
        <Stack.Screen key={'History'} name="History" component={History} options={{ headerTitle: '播放历史' }} />
        <Stack.Screen key={'Favorite'} name="Favorite" component={Favorite} options={{ headerTitle: '收藏记录' }} />
        <Stack.Screen key={'Result'} name="Result" component={Result} options={{ headerTitle: true }} />

        <Stack.Screen key={'Actor'} name="Actor" component={Actor} options={{ headerTitle: '女优列表' }} />
        <Stack.Screen key={'PayVideo'} name="PayVideo" component={PayVideo} options={{ headerTitle: '已购视频' }} />
        
        <Stack.Screen key={'Share'} name="Share" component={Share} options={{ headerShown: false  }} />
        <Stack.Screen key={'Bind'} name="Bind" component={Bind} options={{ headerTitle: '绑定' }} />
        <Stack.Screen key={'Register'} name="Register" component={Register} options={{ headerTitle: '注册' }} />
        <Stack.Screen key={'Login'} name="Login" component={Login} options={{ headerTitle: '登录' }} />
        <Stack.Screen key={'ResetPwd'} name="ResetPwd" component={ResetPwd} options={{ headerTitle: '重置密码' }} />
        
        <Stack.Screen key={'Search'} name="Search" component={Search} options={{ headerShown: false }} />

      </Stack.Navigator>
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  tabIcon: {
    width: px(50),
    height: px(50)
  },
  tabText:{
    fontFamily:'',
    color:'#cccccc',
    fontWeight:'bold',
    fontSize:px(22)
  },
  tabTextFocus:{
    fontFamily:'',
    color:'#EE0D24',
    fontWeight:'bold',
    fontSize:px(24)
  }
});

export default AppContainer;