import * as ScreenOrientation from 'expo-screen-orientation'
import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, ScrollView, Pressable, FlatList, BackHandler, Platform } from 'react-native'
import { Video } from 'expo-av'
import { setStatusBarHidden } from 'expo-status-bar'
import React from 'react'
import * as Application from "expo-application";
import VideoPlayer from 'expo-video-player'
import API, { axiosAPI } from '../common/API';
import { windowHeight, windowWidth } from '../common/Platform'
import px from '../utils/px'
import { getItem, setItem, clear } from '../utils/Storage'
import { BaseStyles, CommonStyles } from '../common/CommonStyle'
import StringUtils from '../utils/StringUtils'
import VideoItem from '../common/VideoItem'
import { mv, recommend } from '../utils/ResourceHelper'
import Hls from '../common/hls.js'
import FastImage from '../components/FastImage'
// import VideoWeb from './VideoWeb'
import DPlayer from 'dplayer';
import CarouselList from '../components/CarouselList'

export default class VideoDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inFullscreen: false,
            favorite: false,
            url: null,
            item: {},
            playing: false,
            videos: [],
            canView: false,
            isLoad:false

        }
        this.needBuy = 0;
        this.currentItem = this.props.route.params.item;
        this.refVideo = React.createRef();
    }

    componentWillUnmount() {
        if (Platform.OS === 'android') {
            ScreenOrientation.removeOrientationChangeListeners();
            BackHandler.removeEventListener('hardwareBackPress', this.onBackAndroid);
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)

        }
        $cld();

    }

    componentDidMount = async () => {
        if (Platform.OS === 'android') {
            ScreenOrientation.unlockAsync()
            BackHandler.addEventListener('hardwareBackPress', this.onBackAndroid);
            ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
                if (orientationInfo.orientation > 2) {
                    if (this.state.inFullscreen)
                        return;
                    this.setState({ inFullscreen: true }, () => {
                        setStatusBarHidden(true, 'fade')
                    })
                } else {
                    if (!this.state.inFullscreen)
                        return;
                    this.setState({ inFullscreen: false }, () => {
                        setStatusBarHidden(false, 'fade')
                    })
                }
            });
        }


        this.loadData(this.props.route.params.item);

        const { data: videos } = await recommend({ tags: this.props.route.params.item.tags });
        this.setState({ videos })

    };

    onBackAndroid = () => {
        if (!this.state.inFullscreen)
            return;
        this.exitFull()
        return true
    }

    renderItem = ({ item }) => {
        return (
            <VideoItem item={item} onPress={this.onClickRecommend} width={px(352)} />
        )
    }

    loadData = async (item) => {
        this.needBuy = 0;
        let canView = false;
        let isVip = false;
        let url = null;
        if ($hasOrder) {
            await axiosAPI.post(`info`, {place:'Detail'});
        }
        $ld()
        try {

            let tokenInfo = await getItem('TOKENINFO');
            if (!StringUtils.isEmpty(tokenInfo)) {
                const { vip } = $infoData.views;
                isVip = vip;
            }
            let favoriteList = await getItem('Favorite') || [];
            let favorite = favoriteList.findIndex(it => it.id == item.id) >= 0;

            if(isVip){
                url = await API.PLAY(this.currentItem.id);
                canView = true;
                $hasOrder = false;
                if(!url){
                    canView = false;
                    this.needBuy = 2;
                }
            }else if(!StringUtils.isEmpty(tokenInfo)){
                this.needBuy = 1;
            }

            if (!canView) {
                url = await mv(this.currentItem.id)
            }

            this.setState({
                isLoad:true,
                canView,
                favorite,
                item,
                url
            }, () => {
                // if (canView && Platform.OS == 'web') {
                //     // this.webVideo.loadSource(url)
                //     if ($DEVICE_TYPE != 'iOS') {
                //         const videos = document.getElementsByTagName('video');
                //         for (let video of videos) {
                //             if (video.src == url) {
                //                 const hls = new Hls();
                //                 hls.loadSource(url);
                //                 hls.attachMedia(video);
                //             }
                //         }
                //     }
                // }
                $cld()
            })
        } catch (err) {
            $cld()
        }

    }

    addViewHistory = async () => {
        let data = await getItem('HISTORY') || [];
        let isExist = data.findIndex(it => it.id == this.currentItem.id) >= 0;
        if (isExist) return;
        data.unshift(this.currentItem);
        data.splice(100);
        setItem('HISTORY', data);
    }

    collect = async () => {
        let data = await getItem('Favorite') || [];
        let idx = data.findIndex(element => element.id == this.currentItem.id);
        if (idx >= 0) {
            data.splice(idx, 1)
        } else {
            data.unshift(this.currentItem);
        }
        setItem('Favorite', data);
        this.setState({ favorite: !this.state.favorite })
    };

    onClickRecommend = item => {
        this.currentItem = item;
        this.setState({
            url: null,
            isLoad:false
        }, () => {
            $cld()
            this.loadData(item)

        })

    };

    startPlay = () => {
        this.addViewHistory();
    }

    stopPlay = async (item) => {
        this.refVideo.current.stopAsync();
    }

    enterFull = async () => {
        this.setState({ inFullscreen: true }, () => {
            setStatusBarHidden(true, 'fade')
        })
        if (Platform.OS === 'android') {
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE)
        }
    }

    exitFull = async () => {
        this.setState({ inFullscreen: false }, () => {
            setStatusBarHidden(false, 'fade');
            if (Platform.OS === 'android') {
                ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)
            }
        })
    }

    renderWebPlayer =(url)=>{
        const dp = new DPlayer({
            container: document.getElementById('video'),
            loop: true,
            autoplay: true,
            lang: 'zh-cn',
            volume: 0.7,
            video: {
                url: url,
                type: 'customHls',
                customType: {
                    'customHls': function (video, player) {
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                    }
                }
            },
        });
        this.startPlay()
    }


    renderPlayer = (url, inFullscreen) => {
            return(
                    <>
                        <VideoPlayer
                            videoProps={{
                                isLooping: true,
                                shouldPlay: true,
                                resizeMode: Video.RESIZE_MODE_CONTAIN,
                                source: { uri: url },
                                ref: this.refVideo,
                                onReadyForDisplay: this.startPlay
                            }}
                            autoHidePlayer={false}
                            fullscreen={{
                                inFullscreen: inFullscreen,
                                enterFullscreen: this.enterFull,
                                exitFullscreen: this.exitFull,
                            }}
                            style={{
                                videoBackgroundColor: 'black',
                                height: inFullscreen ? windowWidth : windowWidth * (9 / 16),
                                width: inFullscreen ? windowHeight : windowHeight,
                            }}
                            icon={{
                                loading: <Text></Text>


                            }}
                        />
                    </>
            )
    }

    goCharge = async ()=>{
        let mobile = await getItem('MOBILE');
        let proxy = await getItem('PROXY');
        let device = await getItem('DEVICE');
        let system = 'native';
        if (Platform.OS == 'web') {
          let u = navigator.userAgent;
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          system = isiOS ? 'ios' : "android";
        }

        if (StringUtils.isEmpty(device)) {
            device = StringUtils.randomString(40);
        }

        const { data, error } = await axiosAPI.post('register', { username : device, proxy , mobile ,system})

        if (error) {
            $toast(error.message)
            return;
        }

        await API.Info(data);
        this.props.navigation.navigate('Charge');
    }

    /**加载视频时 */
    renderMv = (url) => {
        let tipTxt = '预览中,免费注册播放完整版';
        let title = '注册后可获得终身账号并且赠送300钻石,充值获得超值VIP,赶快加入体验更多精彩!';
        let cancelTxt = '充值';
        let confirmTxt = '登录/注册';
        let cancelCallback = ()=>{
            this.goCharge()
        };
        let callback = ()=>{
            this.props.navigation.navigate('Login');
        }
        if(this.needBuy == 2){
            tipTxt = `花费${this.currentItem?.price || 68}钻石购买影片`;
            title = '是否购买影片';
            cancelTxt = '取消';
            confirmTxt = '确定';
            cancelCallback = null;
            callback = async () => {
                let bool = await API.BUY(this.currentItem.id);
                if(bool){
                    this.loadData(this.currentItem)
                }else{
                    $box({
                        text: '钻石不足,前往充值钻石',
                        cancelTip: '取消',
                        confirmTip: '确定',
                        callback: async () => {
                            this.props.navigation.navigate('Charge')
                        }
                    })
                }
               
            }
        }else if(this.needBuy == 1){
            tipTxt = `开通VIP会员极速秒播,VIP视频无限观看`;
            title = '是否开通VIP';
            cancelTxt = '取消';
            confirmTxt = '确定';
            cancelCallback = null;
            callback = async () => {
                this.props.navigation.navigate('Charge')
            }
        } 
        return (
            this.state.isLoad ?
            <>
                <VideoPlayer
                    videoProps={{
                        isLooping: true,
                        shouldPlay: true,
                        resizeMode: Video.RESIZE_MODE_CONTAIN,
                        source: { uri: url },
                    }}
                    slider={{
                        visible: false,
                    }}
                    fullscreen={{
                        visible: false,
                    }}
                    timeVisible={false}
                    style={{
                        videoBackgroundColor: 'transparent',
                        height: windowWidth * (9 / 16),
                        width: windowHeight,
                    }}
                    icon={{
                        size: 0
                    }}
                />
                <Pressable style={{
                    width: px(750),
                    height: px(421),
                    justifyContent: "center",
                    alignItems: "center",
                    position: 'absolute',
                    backgroundColor:'rgba(0,0,0,0.25)',
                    top: 0
                }} onPress={() => {
                    $box({
                        text: title,
                        cancelTip: cancelTxt,
                        confirmTip: confirmTxt,
                        callback: callback,
                        cancelCallback: cancelCallback
                    })
                }}>
                    <Text style={{ fontSize: px(30), color: '#fff' }}>{tipTxt}</Text>
                    <Image source={require('../../assets/detail/videoPlayBtn.png')}
                        style={{
                            width: px(100),
                            height: px(100)
                        }} />
                </Pressable>
            </>
            :
            <ImageBackground source={{uri:this.state.item.imgData}}
                style={{
                    width: px(750),
                    height: px(421),
                }}>
            </ImageBackground> 
        )
    }

    renderBanner(){
        if(StringUtils.isEmpty($appConfig.carousel_video)){
            return null
        }else{
            let arr = [];
            $appConfig.carousel_video.forEach(e => {
                arr.push(JSON.parse(e))
            });
            return <CarouselList data={arr} />
            // const { url, image, height = 80, width = 480 } = $appConfig.banner_video;
            // return (
            //     <Pressable onPress={() => StringUtils.click(url)}>
            //         <FastImage
            //             url={image}
            //             style={{
            //                 width: windowWidth,
            //                 height: (height / width) * windowWidth,
            //                 marginBottom: px(10)
            //             }}
            //             resizeMode={'cover'}
            //         />
            //     </Pressable>
            // )
        }
        
    }

    render() {
        const { item: { name, tags = [], duration, views }, url, favorite, inFullscreen } = this.state;
        return (
            <View style={CommonStyles.body}>
                <View style={inFullscreen ? styles.fullScreen : { height: windowWidth * (9 / 16) }}>
                    <div style={{height: windowWidth * (9 / 16)}} id="video"></div>
                    {this.state.canView ? this.renderWebPlayer(url) : this.renderMv(url)}
                </View>
                {
                    !inFullscreen ?
                    <Pressable onPress={() => { this.props.navigation.goBack() }} style={{ position: 'absolute', padding: px(30), left: 0, top: 0 }}>
                        <Image
                            style={{ width: px(50), height: px(50) }}
                            source={require("../../assets/btn_back_default.png")}
                        />
                    </Pressable> : null
                }
                {
                    !inFullscreen ?
                        <View style={{ flex: 1, paddingHorizontal: px(10), width: windowWidth, alignSelf: 'center' }} >
                            <Text numberOfLines={2} style={{ color: BaseStyles.name_color, height: px(65), lineHeight: px(34), marginTop: px(20), fontWeight: 'bold', fontFamily: 'System', fontSize: px(28) }}>{name}</Text>
                            <View style={styles.tagContent}>
                                {
                                    tags.map((tag, index) => {
                                        return (
                                            <View style={styles.tagItem} key={index.toString()}>
                                                <Text style={styles.keyText} >{tag}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>

                            <View style={{
                                alignItems: "center",
                                flexDirection: "row",
                                margin: px(10),
                                paddingBottom: px(20),
                                justifyContent: 'space-between',
                                borderBottomWidth: 1,
                                borderColor: '#393d60'
                            }} >

                                <Text style={{ marginLeft: px(10), color: '#777880', fontFamily: 'System', fontSize: px(26) }}  >时长: {StringUtils.timeFilter(duration || 0)}</Text>


                                <Text style={{ marginRight: px(30), color: '#777880', fontFamily: 'System', fontSize: px(26) }}  >热度: {views || 0}</Text>
                                <TouchableOpacity onPress={this.collect}>
                                    <View style={{
                                        alignItems: "center",
                                        flexDirection: "row",
                                        marginRight: px(30)
                                    }} >
                                        {favorite ?
                                            <Image source={require("../../assets/detail/shoucang01.png")}
                                                style={{ width: px(38), height: px(38) }} /> :
                                            <Image source={require("../../assets/detail/shoucang.png")}
                                                style={{ width: px(38), height: px(38) }} />}
                                        <Text style={{ marginLeft: px(10), color: '#777880', fontFamily: 'System', fontSize: px(26) }}  >收藏</Text>
                                    </View>
                                </TouchableOpacity>
                                {/* <TouchableOpacity onPress={() => this.props.navigation.navigate('History')}>
                                    <View style={{
                                        alignItems: "center",
                                        flexDirection: "row"
                                    }} >
                                        <Image source={require("../../assets/detail/fenxiang.png")}
                                            style={{ width: px(35), height: px(35) }} />
                                        <Text style={{ marginLeft: px(10), color: '#777880', fontFamily: 'System', fontSize: px(26) }}  >分享</Text>
                                        <Text style={{ marginLeft: px(10), color: '#777880', fontFamily: 'System', fontSize: px(26) }}  >{Application.nativeApplicationVersion || ''}</Text>
                                    </View>
                                </TouchableOpacity> */}
                            </View>
                            {this.renderBanner()}
                            
                            <FlatList
                                data={this.state.videos}
                                style={{ flex: 1 }}
                                contentContainerStyle={{ alignItems: 'center' }}
                                keyExtractor={item => item.id}
                                renderItem={this.renderItem}
                                numColumns={2}
                                showsVerticalScrollIndicator={false}
                            />

                        </View> : null
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    fullScreen: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black'
    },
    tagContent: {
        marginVertical: px(10),
        flexDirection: 'row',
        overflow: 'hidden'
    },

    tagItem: {
        backgroundColor: BaseStyles.tagItem_bg_color,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        margin: px(2),
        paddingHorizontal: px(5)
    },

    keyText: {
        color: '#999999',
        fontSize: px(26),
        fontFamily: 'System',
        fontWeight: 'bold'
    },
})
