import React from 'react'
import { Text, View, StyleSheet, Pressable, TextInput } from 'react-native';
import px from "../utils/px"
import API, { axiosAPI } from '../common/API';
import { BaseStyles, CommonStyles } from '../common/CommonStyle';
import CodeButton from '../components/CodeButton';

const validityPhone = function (str) {
    const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
    return reg.test(str)
}

const validityEmail = function (str) {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(str)
}

const validityPassWord = function (str) {
    const reg = /^[a-zA-Z\d]{6,16}$/;
    return reg.test(str)
}

export default class ResetPwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            password2: '',
            code: '',
        }
    }

    onChangeRegPhone = (username) => {
        this.setState({ username });
    }

    onChangeRegPwd = (password) => {
        this.setState({ password });
    }

    onChangeRegPwd2 = (password2) => {
        this.setState({ password2 });
    }
    
    onChangeRegCode = (code) => {
        this.setState({ code });
    }

    onGetRegCode = async () => {
        const { username , password , password2} = this.state;
        if (!validityPhone(username) && !validityEmail(username)) {
            $toast('手机号码或邮件错误')
            return;
        }
        if (password == '') {
            $toast('请输入新密码')
            return;
        }
        if (!validityPassWord(password)) {
            $toast('密码长度6-16位')
            return;
        }
        if (password2 == '') {
            $toast('请再次输入密码')
            return;
        }
        if (password != password2) {
            $toast('两次输入密码不一致')
            return;
        }

        const { error } = await axiosAPI.post('sms', { username });
        this.countDownButton.startCountDown();
        if (error) {
            $toast(error.message)
            return;
        }
        $toast('发送成功')
    }

    onSubmit = async () => {
        const { username , code, password } = this.state;
        if (code == '') {
            $toast('请输入验证码')
            return;
        }
     
        const { data, error } = await axiosAPI.post('reset', { username , code , password })
        if (error) {
            $toast(error.message)
            return;
        }

        $toast('重置成功')
        await API.Info(data);
        this.props.navigation.navigate('Mine');
    }

    render() {
        return (
            <View style={CommonStyles.body}>
                <View style={{
                    margin: px(30),
                }}>
                    <TextInput style={styles.inputTxt}
                        placeholder={'请输入手机号或邮箱'}
                        blurOnSubmit={true}
                        value={this.state.username}
                        placeholderTextColor={BaseStyles.input_text_color}
                        onChangeText={(v) => this.onChangeRegPhone(v)}
                        underlineColorAndroid="transparent"
                        selectTextOnFocus={false}
                        keyboardType={'numeric'}
                    />
                    <View style={{
                        marginTop: px(30),
                    }}>
                        <TextInput style={styles.inputTxt}
                            placeholder={'请输入新密码'}
                            value={this.state.password}
                            placeholderTextColor={BaseStyles.input_text_color}
                            onChangeText={(v) => this.onChangeRegPwd(v)}
                            underlineColorAndroid="transparent"
                            secureTextEntry={true}
                        />
                    </View>
                    <View style={{
                        marginTop: px(30),
                    }}>
                        <TextInput style={styles.inputTxt}
                            placeholder={'请再次输入新密码'}
                            value={this.state.password2}
                            placeholderTextColor={BaseStyles.input_text_color}
                            onChangeText={(v) => this.onChangeRegPwd2(v)}
                            underlineColorAndroid="transparent"
                            secureTextEntry={true}
                        />
                    </View>
                    <View style={{
                        marginVertical: px(30),
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <TextInput style={[styles.inputTxt, { flex: 1 }]}
                            placeholder={'请输入短信验证码'}
                            value={this.state.code}
                            placeholderTextColor={BaseStyles.input_text_color}
                            onChangeText={(v) => this.onChangeRegCode(v)}
                            underlineColorAndroid="transparent"
                            selectTextOnFocus={false}
                            keyboardType={'numeric'}
                        />
                        <CodeButton
                            beginText='获取验证码'
                            endText='重新获取'
                            count={60}
                            activeViewStyle={styles.loginBtn}
                            disableViewStyle={styles.loginBtn}
                            pressAction={this.onGetRegCode}
                            changeWithCount={(count) => count + 's'}
                            id='tranfer'
                            ref={(e) => {
                                this.countDownButton = e
                            }}
                        />
                    </View>
                    <Pressable onPress={this.onSubmit} style={{
                        backgroundColor: '#ff4d4d',
                        padding: px(30),
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4
                    }}>
                        <Text style={{
                            color: '#fff'
                        }}>确认重置</Text>

                    </Pressable>
                </View>
            </View>
        )
    }

}

const styles = StyleSheet.create({
    inputTxt: {
        fontSize: px(28),
        color: '#fff',
        backgroundColor: BaseStyles.input_color,
        borderRadius: 4,
        padding: px(20),
        // borderWidth:1,
        // borderColor:'#fff'
    },

    loginBtn: {
        backgroundColor: '#ff4d4d',
        paddingVertical: px(30),
        width: px(200),
        marginLeft: px(30),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4
    }
});