/** 公共样式表 **/
import { StyleSheet } from 'react-native';
import px from '../utils/px';

export const BaseStyles = {
    title_color: "#000",
    body_color: "#262941",
    container_color: "#262941",
    
    input_color: '#3E4164',
    input_text_color: '#777880',

    tagItem_bg_color:'#3E4164',
    tag_title_color: '#fff',
    name_color:'#fff',

    tab_background_color:'#262941',
    tab_active_color: "#EE0D24",
    tab_inactive_color:'#fff',

    tab_bottom_color:'#1A1C2C'

};

export const CommonStyles = StyleSheet.create(
    {
        /**容器**/
        container: {
            flex: 1,
            backgroundColor: BaseStyles.container_color
        },
        body: {
            flex: 1,
            backgroundColor: BaseStyles.body_color
        },

        /***文本大小和颜色*/
        text_white: { fontSize: px(22), color: '#ffffff' },
    });
